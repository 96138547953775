<script>

import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
import Layout from "../../layouts/auth";
import appConfig from "@/app.config";
import { mapState } from "vuex";

import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { Api } from '../../../helpers';

/**
 * Register component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Register",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      connectTo:"email",
      user: {
        name: "",
        surname: "",
        username: "",
        phone: "",
        email: "",
        password: "",
        // selectedfile: null,
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    };
  },
  validations: {
    user: {
      name: {
        required: helpers.withMessage("Name is required", required),
      },
      surname: {
        required: helpers.withMessage("Surname is required", required),
      },
      username: {
        required: helpers.withMessage("Username is required", required),
      },
      phone: {
        required: helpers.withMessage("phone is required", required),
      },
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      password: {
        required: helpers.withMessage("Password is required", required),
      },
    },
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    connectEmail(){
      this.connectTo = "email"
    },
    connectPhone(){
      this.connectTo = "telephone"
    },

    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    onFileSelected(event) {
      this.selectedfile = event.target.files[0];
    },
    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      // if (this.v$.$invalid) {
      //   return;
      // } else {
        // if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
        //   this.tryingToRegister = true;
        //   // Reset the regError if it existed.
        //   this.regError = null;
        //   return (
        //     this.register({
        //       username: this.user.username,
        //       email: this.user.email,
        //       password: this.user.password,
        //     })
        //       // eslint-disable-next-line no-unused-vars
        //       .then((token) => {
        //         this.tryingToRegister = false;
        //         this.isRegisterError = false;
        //         this.registerSuccess = true;
        //         if (this.registerSuccess) {
        //           this.$router.push(
        //             this.$route.query.redirectFrom || {
        //               name: "default",
        //             }
        //           );
        //         }
        //       })
        //       .catch((error) => {
        //         this.tryingToRegister = false;
        //         this.regError = error ? error : "";
        //         this.isRegisterError = true;
        //       })
        //   );
        // } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
        //   const { email, username, password } = this.user;
        //   if (email && username && password) {
        //     this.registeruser(this.user);
        //   }
        // } else if (process.env.VUE_APP_DEFAULT_AUTH === "authapi") {
          // const fd = new FormData();
          // fd.append('image', this.selectedfile, this.selectedfile.name);

          
          if(this.connectTo == "email"){
            // const self = this;




            Api.post("/authentication/api/auth/signup-email",{
              name:this.user.name,
              surname:this.user.surname,
              username: this.user.username,
              email: this.user.email,
              password: this.user.password,
            })
            .then((res) => {
              console.log(res);
              this.user = res.data
                if (this.user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    // localStorage.setItem('name', this.user.name);
                    // localStorage.setItem('surname', this.user.surname);
                    // localStorage.setItem('email', this.user.email);
                    // localStorage.setItem('username', this.user.username);
                    // localStorage.setItem('telephone', this.user.telephone);
                    // localStorage.setItem('id', this.user.id)
                    // localStorage.setItem('roles', this.user.roles);
                    localStorage.setItem('user', this.user)
                    localStorage.setItem('jwtToken', this.user.token);
                }
              this.$router.push(
                    this.$route.query.redirectFrom || {
                      name: "dashboard",
                    }
                  );
            }).catch((error) => {
                console.log(error);
                this.tryingToRegister = false;
                this.regError = error.message;
                this.isRegisterError = true;
            });
          }

          if(this.connectTo == "telephone"){
            Api
            .post("/authentication/api/auth/signup-phone",{
              codepays: "237",
              name: this.user.name,
              password: this.user.password,
              surname: this.user.surname,
              telephone: this.user.phone,
              username: this.user.username
            })
            .then((res) => {
              console.log(res);
              this.user = res.data
                if (this.user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    // localStorage.setItem('name', this.user.name);
                    // localStorage.setItem('surname', this.user.surname);
                    // localStorage.setItem('email', this.user.email);
                    // localStorage.setItem('username', this.user.username);
                    // localStorage.setItem('telephone', this.user.telephone);
                    // localStorage.setItem('id', this.user.id)
                    // localStorage.setItem('roles', this.user.roles);
                    localStorage.setItem('user', JSON.stringify(this.user))
                    localStorage.setItem('jwtToken', this.user.token);
                }
              this.$router.push(
                    this.$route.query.redirectFrom || {
                      name: "dashboard",
                    }
                  );
            }).catch((error) => {
                console.log(error);
                this.tryingToRegister = false;
                this.regError = error.message;
                this.isRegisterError = true;
            });
          }
        // }
      // }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Bienvenue a vous !</h5>
                  <p>Créez votre compte pour administer la plateforme</p>
                  {{ this.user.phone }}
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="@/assets/images/profile-img.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="@/assets/logos/coloredlogo.png" alt height="34"/>
                  </span>
                </div>
              </router-link>
            </div>

            <b-alert
              v-model="registerSuccess"
              class="mt-3"
              variant="success"
              dismissible
              >Registration successfull.</b-alert
            >

            <b-alert
              v-model="isRegisterError"
              class="mt-3"
              variant="danger"
              dismissible
              >{{ regError }}</b-alert
            >

            <div
              v-if="notification.message"
              :class="'alert ' + notification.type"
            >
              {{ notification.message }}
            </div>

            <div class="choix-connect">
              <div :class="{connectTo: connectTo === 'email'}" @click="connectEmail">
                <button>Email</button>
              </div>
              <div :class="{connectTo: connectTo === 'telephone'}" @click="connectPhone">
                <button>Telephone</button>
              </div>
            </div>

            <b-form class="p-2" @submit.prevent="tryToRegisterIn">
              <b-form-group
                class="mb-3"
                id="email-group"
                label="Name"
                label-for="Name"
              >
                <b-form-input
                  id="Name"
                  v-model="user.name"
                  type="text"
                  placeholder="Enter Name"
                  :class="{
                    'is-invalid': submitted && v$.user.name.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && v$.user.name.$error"
                  class="invalid-feedback"
                >
                  <span v-if="v$.user.name.required.$message">{{
                    v$.user.name.required.$message
                  }}</span>
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="email-group"
                label="Surname"
                label-for="username"
              >
                <b-form-input
                  id="Surname"
                  v-model="user.surname"
                  type="text"
                  placeholder="Enter Surname"
                  :class="{
                    'is-invalid': submitted && v$.user.surname.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && v$.user.surname.$error"
                  class="invalid-feedback"
                >
                  <span v-if="v$.user.surname.required.$message">{{
                    v$.user.surname.required.$message
                  }}</span>
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="email-group"
                label="Username"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  v-model="user.username"
                  type="text"
                  placeholder="Enter username"
                  :class="{
                    'is-invalid': submitted && v$.user.username.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && v$.user.username.$error"
                  class="invalid-feedback"
                >
                  <span v-if="v$.user.username.required.$message">{{
                    v$.user.username.required.$message
                  }}</span>
                </div>
              </b-form-group>


              <b-form-group
                class="mb-3"
                id="email-group"
                label="Phone"
                label-for="Phone"
                v-if="connectTo == 'telephone'"
              >
                <b-form-input
                  
                  id="Phone"
                  v-model="user.phone"
                  type="Phone"
                  placeholder="Enter Phone"
                  :class="{
                    'is-invalid': submitted && v$.user.phone.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && v$.user.phone.$error"
                  class="invalid-feedback"
                >
                  <span v-if="v$.user.phone.required.$message">{{
                    v$.user.phone.required.$message
                  }}</span>
                </div>
              </b-form-group>


              <b-form-group
                class="mb-3"
                id="fullname-group"
                label="Email"
                label-for="email"
                v-if="connectTo == 'email'"
              >
                <b-form-input
                  
                  id="email"
                  v-model="user.email"
                  type="email"
                  placeholder="Enter email"
                  :class="{
                    'is-invalid': submitted && v$.user.email.$error,
                  }"
                ></b-form-input>
                <div
                  v-for="(item, index) in v$.user.email.$errors"
                  :key="index"
                  class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="password-group"
                label="Password"
                label-for="password"
              >
                <b-form-input
                  id="password"
                  v-model="user.password"
                  type="password"
                  placeholder="Enter password"
                  :class="{
                    'is-invalid': submitted && v$.user.password.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && v$.user.password.$error"
                  class="invalid-feedback"
                >
                  <span v-if="v$.user.password.required.$message">{{
                    v$.user.password.required.$message
                  }}</span>
                </div>
                
              </b-form-group>

              <div class="mt-4 d-grid">
                <b-button type="submit" variant="primary" class="btn-block"
                  >Register</b-button
                >
              </div>

              <!-- <div class="mt-4 text-center">
                <h5 class="font-size-14 mb-3">Sign up using</h5>

                <ul class="list-inline">
                  <li class="list-inline-item">
                    <a
                      href="javascript: void(0);"
                      class="
                        social-list-item
                        bg-primary
                        text-white
                        border-primary
                      "
                    >
                      <i class="mdi mdi-facebook"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="javascript: void(0);"
                      class="social-list-item bg-info text-white border-info"
                    >
                      <i class="mdi mdi-twitter"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="javascript: void(0);"
                      class="
                        social-list-item
                        bg-danger
                        text-white
                        border-danger
                      "
                    >
                      <i class="mdi mdi-google"></i>
                    </a>
                  </li>
                </ul>
              </div> -->

              <!-- <div class="mt-4 text-center">
                <p class="mb-0">
                  By registering you agree to the Skote
                  <a href="javascript: void(0);" class="text-primary"
                    >Terms of Use</a
                  >
                </p>
              </div> -->
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            Already have an account ?
            <router-link to="/login" class="fw-medium text-primary"
              >Login</router-link
            >
          </p>
          <p> {{ $t("footer.copyright") }} </p>
          <p> By <a href="https://www.digitalhouse-int.com/" target="_blank">Digital House International</a></p>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>